import React from "react"
import { Link,graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WaitTimes from '../components/WaitTimes'
import { Helmet } from "react-helmet"
import { VegaLite } from 'react-vega'
const url = require('url');
const chrono = require('chrono-node');
const { parse, format,formatISO } = require('date-fns')



const getDomain = (_url) => {
  return url.parse(_url).host.replace('www.','')
}

const formatDate = (dateString) => {
  let date = parse(dateString,"yyyy-MM-dd'T'HH:mm:ss",new Date())
  return format(date,"MMM do") // h:mm aaaa
}

const clean = (str) => {
  return str
    .replace("&hellip;","")
    .replace("\ufffd","")
}

const IndexPage = ({data}) => {


  const chartData = data.postgres.allCovidDailies.edges.map((edge)=>{
    return {x: edge.node.date, y: edge.node.confirmed, category: edge.node.county}
  })

  let lastStatsByCounty = {}
  let secondLastStatsByCount = {}
  const dailyReversed = data.postgres.allCovidDailies.edges.reverse()

  for(let row of dailyReversed) {
    if(!lastStatsByCounty[row.node.county]) {
      lastStatsByCounty[row.node.county] = row.node
    } else {
      if(!secondLastStatsByCount[row.node.county]) {
        secondLastStatsByCount[row.node.county] = row.node
      }
    }
  }

  let counties = Object.keys(lastStatsByCounty)
  console.log("lastStatsByCounty=", lastStatsByCounty)


  const spec = {
    "$schema": "https://vega.github.io/schema/vega-lite/v4.json",
    "description": "Stock prices of 5 Tech Companies over Time.",
    "width": "container",
    "height": 300,
    "data": {"values": chartData},
    "mark": {
      "type": "line",
      "point": {
        "filled": false,
        "fill": "white"
      }
    },
    "encoding": {
      "x": {"timeUnit": "yearmonthdate", "field": "x", "type": "temporal","title":"Date"},
      "y": {"aggregate":"sum", "field": "y", "type": "quantitative","title":"Active Cases"},
      "color": {"field": "category", "type": "nominal","title":"County"}
    }
  }
  
  


  return (
  <Layout>

    <Helmet>
    </Helmet>

  <div className='justify-content-center align-items-center flex-column'>

    <div >
      <VegaLite spec={spec}  />
    </div>

    <div className="d-flex">
        {counties.map((county)=>{

          const diff = lastStatsByCounty[county].confirmed - secondLastStatsByCount[county].confirmed

          return (
            <div className="col bg-light mx-1 text-center">
              <h5>{county}</h5>
              <h1>{lastStatsByCounty[county].confirmed}</h1>
              <h5>+{diff}</h5>
            </div>            
          )
        })}

    </div>

    <div>
      {data.postgres.allCoronavirusArticles.edges.map(edge => (
        <div key={edge.node.id} className="my-5">
          <small>{formatDate(edge.node.publishedAt)} - {getDomain(edge.node.url)}</small>
          <h2 className="mb-0"><a href={edge.node.url} target="new">{edge.node.headline}</a></h2>
          <p>{clean(edge.node.excerpt)} {'...'} <a href={edge.node.url} target="new">read more</a></p>
          <hr className='w-25'/>
        </div>
      ))}
    </div>      
  </div>


  </Layout>    
  )
}

export default IndexPage
export const query = graphql`
  query {
    postgres {  
      allCovidDailies(orderBy: DATE_ASC) {
        edges {
          node {
            county
            state
            date
            confirmed
            id
          }
        }
      }      
      allCoronavirusArticles(first: 50, orderBy: PUBLISHED_AT_DESC) {
        edges {
          node {
            id
            url
            publishedAt
            headline
            excerpt
          }
        }
      }
    }
  }
`